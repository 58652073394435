/**
 * Define Mapbox layer styles, images, etc.
 * All data elements are defined in `plugins` directory on a per-project basis.
 * `plugins/data.js` defines metric metadata, metric data getter methods, etc.,
 *    , and default settings
 * `plugins/sources.js` defines the map sources and styles.
 * `plugins/layers.js` defines the layers and styles
 */

// 3rd party packages
import * as d3 from 'd3/dist/d3.min'
import moment from 'moment'

// utilities
import { getLog10Scale, comma } from '../../../misc/Util'

// assets and styles
import dots from './assets/images/dots.png'
import {
  goldenrod,
  softYellow2,
  softBlue,
  grayishCyan,
  darkTeal,
  noDataGray,
} from '../../../../assets/styles/vars.scss'

// constants
// define default pattern style used below
const defaultPatternStyle = key => {
  return {
    'fill-pattern': 'dots',
    'fill-opacity': [
      'case',
      ['==', ['feature-state', key], null],
      0,
      ['<', ['feature-state', key], 25],
      1,
      0,
    ],
  }
}
// define default outline style used below
const defaultOutlineStyle = key => {
  return {
    'line-color': [
      'case',
      ['==', ['feature-state', 'clicked'], true],
      '#ffffff',
      ['==', ['feature-state', 'hovered'], true],
      '#ffffff',
      '#ffffff',
    ],
    'line-width': [
      'case',
      ['==', ['feature-state', 'clicked'], true],
      1.5,
      ['==', ['feature-state', 'hovered'], true],
      1,
      0.25,
    ],
  }
}

// define circle styles
// each key corresponds to a style, or to a layer ID that uses the style.
// The styles may be referenced in `../setup.js` directly by layer ID or by a
// style ID which is specified in the `./layers.js` as `styleId`.
//
// The value of each key is a function that returns a data-driven style based
// on the feature state defined by the `key` passed as an argument. Styles that
// are not data-driven should be represented as functions without any arguments
const circleStyles = {}

// similar for fill styles
const fillStyles = {
  'disputed_borders-outline': {
    'line-color': 'white',
    'line-width': ['step', ['zoom'], 1, 3, 2],
    'line-dasharray': [2, 2],
  },
  'score-bins': key => {
    return {
      'fill-color': [
        'case',
        ['==', ['feature-state', key], null],
        noDataGray,
        ['>=', ['feature-state', key], 80],
        darkTeal,
        ['>=', ['feature-state', key], 60],
        softBlue,
        ['>=', ['feature-state', key], 40],
        grayishCyan,
        ['>=', ['feature-state', key], 20],
        softYellow2,
        ['>=', ['feature-state', key], 0],
        goldenrod,
        noDataGray,
      ],
    }
  },
  'score-bins-outline': key => {
    return defaultOutlineStyle(key)
  },
  indicator: key => {
    return {
      'fill-color': [
        'case',
        ['==', ['feature-state', key], null],
        noDataGray,
        ['==', ['feature-state', key], 0.5],
        grayishCyan,
        ['==', ['feature-state', key], 0],
        goldenrod,
        ['==', ['feature-state', key], 1],
        darkTeal,
        noDataGray,
      ],
    }
  },
  'indicator-outline': key => {
    return defaultOutlineStyle(key)
  },
  'indicator-pattern': key => {
    return defaultPatternStyle(key)
  },
  'metric-test-red': key => {
    return {
      'fill-color': [
        'case',
        ['==', ['feature-state', key], null],
        '#eaeaea',
        ['<', ['feature-state', key], 25],
        'transparent',
        '#67001f',
      ],
    }
  },
}

// define layer styles
export const layerStyles = {
  circle: circleStyles,
  fill: fillStyles,
}

// define images used by layers -- if none, then provide empty array
export const layerImages = [
  {
    name: 'dots',
    asset: dots,
  },
]
