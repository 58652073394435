import React from 'react'
import classNames from 'classnames'
import styles from '../nav.module.scss'

import styled from 'styled-components'

const Caret = styled.span`
  position: relative;
  font-size: 0.5em;
  margin-left: 0.5em;
  top: -0.125em;
`

const DropDown = props => {
  // console.log(props.openDropDown)
  return (
    <div
      className={styles.countryLinkContainer}
      onMouseEnter={() => {
        props.setOpenDropDown(props.title)
      }}
    >
      {props.openDropDown === props.title && (
        <div
          className={styles.countryPicker}
          onMouseLeave={() => {
            props.setOpenDropDown('')
          }}
        >
          <div className={styles.tabContainer}>
            <div
              className={classNames(
                styles.tab,
                props.page === props.pageName ? styles.active : ''
              )}
              style={{ zIndex: 3 }}
              onTouchStart={() => {
                props.setOpenDropDown(prev =>
                  prev === props.title ? '' : props.title
                )
              }}
            >
              {props.title}
              <Caret>▼</Caret>
            </div>
          </div>
          <div
            className={styles.content}
            style={{ overflowY: props.overflowY || 'scroll', zIndex: 2 }}
          >
            {props.children}
          </div>
        </div>
      )}
      <div
        className={classNames(
          styles.countryLink,
          props.page === props.pageName ? styles.active : ''
        )}
      >
        {props.title}
        <Caret>▼</Caret>
      </div>
    </div>
  )
}

export default DropDown
