/**
 * Define metrics, data sources, and metric metadata for use in Mapbox map
 * All data elements are defined in `plugins` directory on a per-project basis.
 * `plugins/data.js` defines metric metadata, metric data getter methods, etc.,
 *    , and default settings
 * `plugins/sources.js` defines the map sources and styles.
 * `plugins/layers.js` defines the layers and styles
 */

// standard packages
import React from 'react'

// 3rd party packages
import * as d3 from 'd3/dist/d3.min'
import classNames from 'classnames'
import moment from 'moment'

// utilities
import { comma, isLightColor, getInitCap } from '../../../misc/Util'

// queries
import ObservationQuery from '../../../misc/ObservationQuery.js'
import TrendQuery from '../../../misc/TrendQuery.js'
import PlaceQuery from '../../../misc/PlaceQuery'
import { execute } from '../../../misc/Queries'

// assets and styles
import styles from '../mapTooltip/maptooltip.module.scss'
import dots from './assets/images/dots.svg'
import {
  goldenrod,
  darkTeal,
  softBlue,
  grayishCyan,
  softYellow2,
  noDataGray,
} from '../../../../assets/styles/vars.scss'

// utilities and local components
import { isEmpty, percentize } from '../../../misc/Util'

// bins for overall adoption legend tooltip
export const bins = [
  {
    name: 'VERY FEW',
    label: '0-19% of policies have been adopted',
  },
  {
    name: 'FEW',
    label: '20-39% of policies ',
  },
  {
    name: 'SOME',
    label: '40-59% ',
  },
  {
    name: 'MANY',
    label: '60-79%',
  },
  {
    name: 'MOST',
    label: '80%+',
  },
]

// // bins for overall adoption legend tooltip
// const bins = [
//   {
//     name: 'NONE',
//     label: 'no policy indicators have been adopted',
//   },
//   {
//     name: 'FEW',
//     label: '1-24% of policy indicators ',
//   },
//   {
//     name: 'SOME',
//     label: '25-50% ',
//   },
//   {
//     name: 'MANY',
//     label: '50-74%',
//   },
//   {
//     name: 'MOST',
//     label: '75%+',
//   },
// ]

// score definition
const scoreDef = (
  <div className={styles.overallAdoptionLegendTooltip}>
    <div className={styles.main}>
      <div className={styles.intro}>
        Calculated for each country based on the percent of policies for which
        data are available that have have a score of “adopted”
      </div>
      <div className={styles.bins}>
        <div className={styles.blocks}>
          {bins.map(({ name, label }, i) => (
            <div key={name + label} className={styles.cellAndLabel}>
              <div
                className={classNames(
                  styles.cell,
                  styles[name.toLowerCase().replace(' ', '')],
                  {
                    [styles.darkFontColor]: i !== 0,
                  }
                )}
              >
                {name}
              </div>
              <div className={styles.label}>{label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

// bins for specific policy legend tooltip
const policyBins = [
  {
    name: 'NOT ADOPTED',
    label:
      'The policy or sub-policies aligned with international standards have not been adopted.',
    className: 'none',
  },
  {
    name: 'PARTIAL',
    label:
      'The policy aligned with international standards has only partially been adopted; or one or more of the sub-policies aligned with international standards has been adopted, but not all.',
    className: 'some',
  },
  {
    name: 'ADOPTED',
    label:
      'The policy or sub-policies aligned with international standards have been adopted.',
    className: 'most',
  },
]

// score definition for specific indicator status
const policyScoreDef = (
  <div
    className={classNames(
      styles.overallAdoptionLegendTooltip,
      styles.indicator
    )}
  >
    <div className={styles.main}>
      <div className={styles.intro}>
        Calculated for each country based on the percent of policies for which
        data are available that have have a score of “adopted”
      </div>
      <div className={styles.bins}>
        <div className={styles.blocks}>
          {policyBins.map(({ name, label }, i) => (
            <div key={name + label} className={styles.cellAndLabel}>
              <div
                className={classNames(
                  styles.cell,
                  styles[name.toLowerCase().replace(' ', '')],
                  {
                    [styles.darkFontColor]: i !== 0,
                  }
                )}
              >
                {name}
              </div>
              <div className={styles.label}>{label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)
// define default parameters for MapboxMap
export const defaults = {
  // default map ID
  mapId: 'global',

  // default date for map to start on
  date: 'recent',

  // min/max dates for date selection -- if there are none, then provide
  // `undefined` as value for each
  minMaxDate: {
    minDate: '2019-02-01',
    maxDate: '2019-06-01',
  },

  // defaults for additional maps...
  global: {
    circle: null,
    fill: 'area',
    priorLayer: 'country-small',
  },
}

// define metrics to retrieve for each map
export const mapMetrics = {
  global: [
    {
      id: 'overall',
      queryFunc: ObservationQuery,
      for: ['fill'],
      params: {
        temporal_resolution: 'yearly',
        spatial_resolution: 'country',
      },
      featureLinkField: 'place_iso',
      styleId: { fill: 'score-bins' },
      trend: false,
      styleOptions: { outline: true, pattern: false },
    },
    {
      id: 'area',
      queryFunc: ObservationQuery,
      for: ['fill'],
      params: {
        temporal_resolution: 'yearly',
        spatial_resolution: 'country',
      },
      featureLinkField: 'place_iso',
      styleId: { fill: 'score-bins' },
      trend: false,
      styleOptions: { outline: true, pattern: false },
    },
    {
      queryFunc: ObservationQuery,
      for: ['fill'],
      params: {
        temporal_resolution: 'yearly',
        spatial_resolution: 'country',
      },
      id: 'indicator',
      featureLinkField: 'place_iso',
      styleId: { fill: 'indicator' },
      trend: false,
      styleOptions: { outline: true, pattern: false },
    },
  ],
}

// reused variables in metric metadata
// color bin scale
export const colorBinScale = v => {
  if (v >= 80) {
    return darkTeal
  } else if (v >= 60) {
    return softBlue
  } else if (v >= 40) {
    return grayishCyan
  } else if (v >= 20) {
    return softYellow2
  } else if (v >= 0) {
    return goldenrod
  } else if (v === null || v === undefined) {
    return noDataGray
  }
}

// // reused variables in metric metadata
// // color bin scale
// export const colorBinScale = v => {
//   if (v >= 75) {
//     return darkTeal
//   } else if (v >= 50) {
//     return softBlue
//   } else if (v >= 25) {
//     return grayishCyan
//   } else if (v >= 0) {
//     return softYellow2
//   } else if (v === 0) {
//     return goldenrod
//   } else if (v === null || v === undefined) {
//     return noDataGray
//   }
// }

// reused variables in metric metadata
// color bin scale
export const colorBinScaleContinuous = v => {
  if (v === undefined || v === null) return noDataGray
  const scale = d3
    .scaleLinear()
    .domain([0, 25, 50, 75, 100])
    .range([goldenrod, softYellow2, grayishCyan, softBlue, darkTeal])
  return scale(v)
}

export const labelBinScale = v => {
  if (v === null || v === undefined) {
    return null
  }
  if (v >= 80) {
    return 'MOST'
  } else if (v >= 60) {
    return 'MANY'
  } else if (v >= 40) {
    return 'SOME'
  } else if (v >= 20) {
    return 'FEW'
  } else if (v >= 0) {
    return 'VERY FEW'
  }
}

export const capLabelBinScale = v => {
  if (v === null || v === undefined) {
    return null
  }
  if (v >= 80) {
    return 'Most'
  } else if (v >= 60) {
    return 'Many'
  } else if (v >= 40) {
    return 'Some'
  } else if (v >= 20) {
    return 'Few'
  } else if (v >= 0) {
    return 'Very few'
  }
}

// metric metadata used for display, tooltips, etc.
export const metricMeta = {
  // unique ID of metric
  overall: {
    // metric definition
    metric_definition: scoreDef,

    // metric name displayed on front-end
    metric_displayname: ({ date }) => {
      if (date) {
        const dateFormatted =
          date === 'recent' ? '' : ` (${date.format('YYYY')})`
        return <span>{`Overall policy adoption${dateFormatted}`}</span>
      } else return 'Overall policy adoption'
    },

    // value formatter for metric
    value: labelBinScale,

    // colors for values
    color: colorBinScale,

    // unit label formatter for metric
    unit: v => (v === 1 ? 'unit' : 'units'),

    // // if metric has trends, the label describing timeframe of those trends
    // trendTimeframe: 'over prior 24 hours',

    // legend styling information
    legendInfo: {
      // when metric is used as a fill:
      fill: {
        // legend entry is for a basemap, or can be bubble
        for: 'basemap',

        // the type of legend entry, e.g., quantized, ordinal, continous
        type: 'quantized',

        // quantized legend `type` only: if true, labels go inside color rects,
        // otherwise below them
        // labelsInside: true,
        labelsRight: true,

        // d3-esque color scale used to obtain legend labels and colors.
        // must implement these instance methods: domain, range
        // TODO reuse
        domain: [
          <>
            DATA NOT
            <br />
            COLLECTED
          </>,
          <>
            INSUFFICIENT
            <br />
            DATA
          </>,
          'VERY FEW',
          'FEW',
          'SOME',
          'MANY',
          'MOST',
        ],
        colorscale: d3
          .scaleOrdinal()
          .domain([
            <>INSUFFICIENT DATA</>,
            <>
              DATA NOT
              <br />
              AVAILABLE
            </>,
            'VERY FEW',
            'FEW',
            'SOME',
            'MANY',
            'MOST',
          ])
          .range([
            'white',
            noDataGray,
            goldenrod,
            softYellow2,
            grayishCyan,
            softBlue,
            darkTeal,
          ]), // TODO dynamically

        // Optional: array of font colors to use for each bin, in order
        specialFontColors: ['#6E7073', '#6E7073'],

        // Optional: class name to apply to entries
        classNames: ['notRounded'],

        // for non-quantized legend `type`: the labels that should be used for
        // each `for` category
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
    },
  },

  // unique ID of metric
  area: {
    // metric definition
    metric_definition: scoreDef,

    // metric name displayed on front-end
    metric_displayname: ({ date, metricName }) => {
      if (date) {
        const dateFormatted =
          date === 'recent' ? '' : ` (${date.format('YYYY')})`
        return (
          <span>{`Policy category adoption${dateFormatted}: ${metricName}`}</span>
        )
      } else return 'Policy category adoption'
    },

    // value formatter for metric
    value: labelBinScale,

    // colors for values
    color: colorBinScale,

    // unit label formatter for metric
    unit: v => (v === 1 ? 'unit' : 'units'),

    // // if metric has trends, the label describing timeframe of those trends
    // trendTimeframe: 'over prior 24 hours',

    // legend styling information
    legendInfo: {
      // when metric is used as a fill:
      fill: {
        // legend entry is for a basemap, or can be bubble
        for: 'basemap',

        // the type of legend entry, e.g., quantized, ordinal, continous
        type: 'quantized',

        // quantized legend `type` only: if true, labels go inside color rects,
        // otherwise below them
        // labelsInside: true,
        labelsRight: true,

        domain: [
          <>
            DATA NOT
            <br />
            COLLECTED
          </>,
          <>
            INSUFFICIENT
            <br />
            DATA
          </>,
          'VERY FEW',
          'FEW',
          'SOME',
          'MANY',
          'MOST',
        ],

        // d3-esque color scale used to obtain legend labels and colors.
        // must implement these instance methods: domain, range
        colorscale: d3
          .scaleOrdinal()
          .domain([
            <>
              DATA NOT
              <br />
              COLLECTED
            </>,
            <>
              INSUFFICIENT
              <br />
              DATA
            </>,
            'VERY FEW',
            'FEW',
            'SOME',
            'MANY',
            'MOST',
          ])
          .range([
            'white',
            noDataGray,
            goldenrod,
            softYellow2,
            grayishCyan,
            softBlue,
            darkTeal,
          ]), // TODO dynamically

        // Optional: class name to apply to entries
        classNames: ['notRounded'],

        // Optional: array of font colors to use for each bin, in order
        specialFontColors: ['#6E7073', '#6E7073'],

        // for non-quantized legend `type`: the labels that should be used for
        // each `for` category
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
      // additional layer legend information...
      circle: {
        for: 'bubble',
        type: 'continuous',
        outline: '#e65d36',
        colorscale: d3
          .scaleLinear()
          .domain([0, 100])
          .range(['rgba(230, 93, 54, 0.6)', 'rgba(230, 93, 54, 0.6)']), // TODO dynamically
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
    },
  },

  indicator: {
    // metric definition
    metric_definition: policyScoreDef,

    // metric name displayed on front-end
    metric_displayname: ({ date, metricName }) => {
      if (date) {
        const dateFormatted =
          date === 'recent' ? '' : ` (${date.format('YYYY')})`
        return <span>{`Policy adoption${dateFormatted}: ${metricName}`}</span>
      } else return 'Policy adoption'
    },

    // value formatter for metric
    value: v => {
      if (v === null || v === undefined) return null
      else if (v === 0.5) {
        return 'Partial'
      } else {
        return v === 1 ? 'Adopted' : 'Not adopted'
      }
    },

    // colors for adopted / not adopted
    color: v => {
      if (v === null || v === undefined) return noDataGray
      else if (v === 0.5) return grayishCyan
      else return v === 1 ? darkTeal : goldenrod
    },

    // unit label formatter for metric
    unit: v => (v === 1 ? 'unit' : 'units'),

    // if metric has trends, the label describing timeframe of those trends
    trendTimeframe: 'over prior 24 hours',

    // legend styling information
    legendInfo: {
      // when metric is used as a fill:
      fill: {
        // legend entry is for a basemap, or can be bubble
        for: 'basemap',

        // the type of legend entry, e.g., quantized, ordinal, continous
        type: 'quantized',

        // quantized legend `type` only: if true, labels go inside color rects,
        // otherwise below them
        labelsInside: true,
        classNames: ['wide'],

        domain: [
          <>
            DATA NOT
            <br />
            COLLECTED
          </>,
          <>
            DATA NOT
            <br />
            AVAILABLE
          </>,
          'not adopted',
          'partial',
          'adopted',
        ],

        // d3-esque color scale used to obtain legend labels and colors.
        // must implement these instance methods: domain, range
        colorscale: d3
          .scaleOrdinal()
          .domain([
            <>
              DATA NOT
              <br />
              COLLECTED
            </>,
            <>
              DATA NOT
              <br />
              AVAILABLE
            </>,
            'not adopted',
            'partial',
            'adopted',
          ])
          .range(['white', noDataGray, goldenrod, grayishCyan, darkTeal]), // TODO dynamically

        // for non-quantized legend `type`: the labels that should be used for
        // each `for` category
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
        // Optional: array of font colors to use for each bin, in order
        specialFontColors: ['#6E7073', '#6E7073'],
      },
      // additional layer legend information...
      circle: {
        for: 'bubble',
        type: 'continuous',
        outline: '#e65d36',
        colorscale: d3
          .scaleLinear()
          .domain([0, 100])
          .range(['rgba(230, 93, 54, 0.6)', 'rgba(230, 93, 54, 0.6)']), // TODO dynamically
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
    },
  },

  '-9999': {
    // metric definition
    metric_definition: 'Test definition',

    // metric name displayed on front-end
    metric_displayname: 'Test metric -9999',

    // value formatter for metric
    value: v => comma(v),

    // unit label formatter for metric
    unit: v => (v === 1 ? 'unit' : 'units'),

    // if metric has trends, the label describing timeframe of those trends
    trendTimeframe: 'over prior 24 hours',

    // legend styling information
    legendInfo: {
      // when metric is used as a fill:
      fill: {
        // legend entry is for a basemap, or can be bubble
        for: 'basemap',

        // the type of legend entry, e.g., quantized, ordinal, continous
        type: 'quantized',

        // quantized legend `type` only: if true, labels go inside color rects,
        // otherwise below them
        labelsInside: true,

        // d3-esque color scale used to obtain legend labels and colors.
        // must implement these instance methods: domain, range
        colorscale: d3
          .scaleOrdinal()
          .domain(['no data', 'under 25', '25 - 49', '50 - 74', '75 or more'])
          .range(['#eaeaea', dots, '#BBDAF5', '#86BFEB', '#549FE2']), // TODO dynamically

        // for non-quantized legend `type`: the labels that should be used for
        // each `for` category
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
      // additional layer legend information...
      circle: {
        for: 'bubble',
        type: 'continuous',
        outline: '#e65d36',
        colorscale: d3
          .scaleLinear()
          .domain([0, 100])
          .range(['rgba(230, 93, 54, 0.6)', 'rgba(230, 93, 54, 0.6)']), // TODO dynamically
        labels: {
          bubble: { min: 'Low', max: 'High' },
          basemap: { min: 'Minimal', max: 'High' },
        },
      },
    },
  },
  // additional metric legend information...
  get '-9997'() {
    return {
      ...this['-9999'],
      metric_displayname: 'Test metric -9997',
      legendInfo: {
        ...this['-9999'].legendInfo,
        circle: {
          for: 'bubble',
          type: 'continuous',
          outline: '#e65d36',
          colorscale: d3
            .scaleLinear()
            .domain([0, 100])
            .range(['transparent', 'transparent']), // TODO dynamically
          labels: {
            bubble: { min: 'Low', max: 'High' },
            basemap: { min: 'Minimal', max: 'High' },
          },
        },
      },
    }
  },
}

/**
 * dataGetter
 * Given the date, map ID, and filters, obtains the data that should be joined
 * to the features on the map
 * @method dataGetter
 * @param  {[type]}   date    [description]
 * @param  {[type]}   mapId   [description]
 * @param  {[type]}   filters [description]
 * @param  {[type]}   map     [description]
 * @return {Promise}          [description]
 */
export const dataGetter = async ({
  date,
  mapId,
  filters,
  fill,
  ...plugins
}) => {
  // get all metrics displayed in the current map
  const metrics = mapMetrics[mapId]

  // if date is "recent" then make it today and specify a lag
  let dates = {}

  // define date parameters for API calls
  if (date !== 'recent') {
    dates = {
      start_date: date.format('YYYY-MM-DD'),
      end_date: date.format('YYYY-MM-DD'),
    }
  } else {
    dates = {
      start_date: `2024-01-01`,
      end_date: `2024-01-01`,
    }
    // const today = new moment()
    // const todayYear = today.format('YYYY')
    // dates = {
    //   start_date: `${todayYear}-01-01`,
    //   end_date: `${todayYear}-01-01`,
    // }
  }

  // collate query definitions based on the metrics that are to be displayed
  // for this map and whether those metrics will have trends displayed or not
  const queryDefs = {}
  metrics.forEach(d => {
    if (d.id !== fill) return
    // if the query for this metric hasn't been defined yet, define it
    if (queryDefs[d.id] === undefined) {
      // add base data query
      const queryParams = {
        queryFunc: d.queryFunc,
        ...d.params,
        ...dates,
        ...plugins,
      }
      if (date === 'recent') {
        queryParams.lag_allowed = 100 // infinite lag - get most recent data
      }
      queryDefs[d.id] = queryParams

      // add trend query if applicable
      if (d.trend === true) {
        const trendKey = d.id.toString() + '-trend'
        queryDefs[trendKey] = {
          queryFunc: TrendQuery,
          ...d.params,
          end: dates.end_date,
        }
      }
    }
  })

  // collate queries in object to be called by the `execute method below`
  const queries = {}
  for (const [k, v] of Object.entries(queryDefs)) {
    queries[k] = v.queryFunc({
      ...v,
    })
  }

  // add place query
  queries.places = PlaceQuery({ by_region: false, place_type: ['country'] })

  // execute queries in parallel
  const results = await execute({ queries })

  // optional: filter results, if this is not being done on the back-end.
  // if it is being done on the back end, comment out or delete the code below
  if (!isEmpty(filters)) {
    // for each filter
    for (const [field, fieldFilters] of Object.entries(filters)) {
      // for each API request result
      for (const [k, v] of Object.entries(results)) {
        // if no filter values, continue
        if (fieldFilters === undefined || fieldFilters.length === 0) continue
        // if only value is "all", continue
        else if (fieldFilters.length === 1 && fieldFilters[0] === 'all')
          continue
        // otherwise, only return values that contain a filter value (simple)
        else {
          results[k] = v.filter(d => {
            return fieldFilters.includes(d[field])
          })
        }
      }
    }
  }

  // return results
  return results
}

/**
 * tooltipGetter
 * Given the current map ID, a datum `d`, the list of metric IDs to `include,
 * and the current date, return an object defining the tooltip header, content,
 * and actions that should be displayed in the `MapTooltip` component.
 * @method tooltipGetter
 * @param  {[type]}      mapId   [description]
 * @param  {[type]}      d       [description]
 * @param  {[type]}      include [description]
 * @param  {[type]}      date    [description]
 * @param  {[type]}      map     [description]
 * @return {Promise}             [description]
 */
export const tooltipGetter = async ({
  d,
  include,
  date,
  map,
  callback,
  plugins,
}) => {
  // define base tooltip data
  const tooltip = {
    tooltipHeader: null,
    tooltipMainContent: null,
    actions: null,
  }

  // if the datum represents a disputed border, then return info about it
  // TODO disputed areas info
  const disputedKeys = [
    'disputed_borders',
    'disputed_areas',
    'areas_v6b',
    'lines_v6',
  ]
  if (disputedKeys.includes(d.sourceLayer)) {
    // return tooltip

    tooltip.tooltipHeader = {
      title: 'Disputed area',
      subtitle: null,
    }
    tooltip.actions = []
    tooltip.tooltipMainContent = [
      {
        customContent: (
          <span className={styles.para}>
            <b>Parties:</b> {d.properties.parties}. Dashed line denotes disputed
            border.
          </span>
        ),
      },
    ]
    if (callback) callback()
    return tooltip
  }

  // for each map type, return the appropriate tooltip formation
  const formattedDate =
    date === 'recent' ? 'most recent data' : 'in ' + date.format('YYYY')

  // get location name (shortest)
  const getLocationInfo = async d => {
    const place_id = [d.properties.id || 196]
    const place = await PlaceQuery({ place_id })
    if (place.length > 0) {
      return { name: place[0][1], has_data: place[0][6] === true }
    } else {
      const alternateName = d.properties.BRK_NAME || ''
      if (
        d.properties.NAME.length < alternateName.length ||
        alternateName === ''
      )
        return { name: d.properties.NAME, has_data: false }
      else return { name: alternateName, has_data: false }
    }
  }

  let codebookLink = '/codebook'

  // get tooltip header
  const isCountry = (d.properties.ISO_A2 || d.properties.WB_A2) !== undefined
  const iso2Tmp = isCountry
    ? d.properties.ISO_A2 !== '-99'
      ? d.properties.ISO_A2.toLowerCase()
      : d.properties.WB_A2.toLowerCase()
    : null
  const getIso2 = (iso2Tmp, d) => {
    if (iso2Tmp.includes('bq')) return 'bq'
    else {
      return iso2Tmp.length === 3 ? d.properties.ISO_A3 : iso2Tmp
    }
  }
  const iso2 = getIso2(iso2Tmp, d)

  const locationInfo = await getLocationInfo(d)
  const noData = locationInfo.has_data === false || iso2 === null
  tooltip.tooltipHeader = {
    title: (
      <div className={styles.nameAndFlag}>
        <img
          id="flagImage"
          style={{ visibility: 'hidden' }}
          src={`https://flags.talusanalytics.com/shiny_100px/${iso2}.png`}
          onLoad={() => showImage()}
        />
        {locationInfo.name}
      </div>
    ),
    subtitle: null,
    badge: null,
  }
  //stop previous country's flag from appearing while new image is loading
  const showImage = () => {
    const img = document.getElementById('flagImage')
    if (img) img.style.visibility = 'visible'
  }

  // get content from metric IDs / values in `state`
  tooltip.tooltipMainContent = []

  // get the current feature state (the feature to be tooltipped)
  const state = map.getFeatureState(d)

  // for each metric (k) and value (v) defined in the feature state, if it is
  // on the list of metrics to `include` in the tooltip then add it to the
  // tooltip, otherwise skip
  for (const [k, v] of Object.entries(state)) {
    // skip metric unless it is to be included
    if (!include.includes(k) || k.includes('-trend') || k.includes('-date'))
      continue
    else {
      // get metric metadata
      const thisMetricMeta = metricMeta[k]

      // define basic tooltip item
      const item = {
        label: thisMetricMeta.metric_displayname({}),
        value: thisMetricMeta.value(v),
        unit: thisMetricMeta.unit(v),
      }

      // return value box
      const getValueBox = (value, label) => {
        return (
          <div className={styles.valueBox}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
          </div>
        )
      }

      // helper function to return correct badge name and color based on
      // key and value
      const getBadgeInfo = (key, value) => {
        const base = {
          label: metricMeta[key].value(value),
          color: metricMeta[key].color(value),
        }
        switch (key) {
          case 'indicator':
          default:
            return {
              type: 'Policy',
              def: '',
              ...base,
            }
          case 'area':
            return {
              type: 'Policy category',
              def: (
                <div>
                  Policy category: <br />
                  <strong>{plugins.indicator}</strong>
                </div>
              ),
              ...base,
            }
          case 'overall':
            return {
              type: null,
              def: <strong>Overall adoption</strong>,
              ...base,
            }
        }
      }

      // special cases for tooltip metrics
      // "indicator"
      if (k === 'indicator' || k === 'area' || k === 'overall') {
        const kMoment = new moment(state[k + '-date'])

        const datumFormattedDate = kMoment.utc().format('YYYY')

        const badgeInfo = getBadgeInfo(k, v)
        const metricContent =
          v !== null ? (
            <>
              <div className={styles.valueBoxes}>
                {badgeInfo.type &&
                  getValueBox(
                    plugins.code || plugins.indicator,
                    badgeInfo.type
                  )}
                {date !== 'recent' && getValueBox(datumFormattedDate, 'Year')}
              </div>
              <div className={styles.badgeContainer}>
                <div
                  style={{
                    backgroundColor: badgeInfo.color,
                    color: isLightColor(badgeInfo.color) ? '#333' : 'white',
                  }}
                  className={classNames(styles.badge)}
                >
                  {badgeInfo.label}
                </div>
                <div className={styles.hideOnMobile}>{badgeInfo.def}</div>
              </div>
            </>
          ) : (
            <span>
              {noData && <i>Data not currently collected for this location</i>}
              {!noData && (
                <i>
                  Data not available{' '}
                  {formattedDate.length === 7 ? ` ${formattedDate}` : ''}
                </i>
              )}
            </span>
          )

        // if `area` then include bar chart of adoption status counts
        const includeBarChart = (k === 'area' || k === 'overall') && v !== null
        const barCharts =
          k !== 'overall'
            ? [
                <div className={styles.barChartHeader} key={'barChartHeader'}>
                  How many {plugins.indicator.toLowerCase()} policies <br />
                  have been adopted?
                </div>,
              ]
            : [
                <div className={styles.barChartHeader} key={'barChartHeader'}>
                  How many policies have been adopted overall?
                </div>,
              ]
        if (includeBarChart) {
          const barChartData = []
          const adoptionBins = ['adopted', 'partial', 'not adopted', 'null']
          let total = 0
          adoptionBins.forEach(bin => {
            const curKey = k + '-' + bin
            const value = state[curKey] || 0
            barChartData.push({
              label: getInitCap(bin),
              value,
              year: state[curKey + '-date'],
            })
            total += value
          })
          barChartData.forEach(({ label, value, year }) => {
            barCharts.push(
              <BarChart
                key={label + ' - ' + value}
                {...{
                  label,
                  value,
                  total,
                  year,
                }}
              />
            )
          })
        }
        item.customContent = (
          <>
            <div className={styles.metricContent}>{metricContent}</div>
            {barCharts && barCharts.length > 1 && barCharts}
          </>
        )
        // hide subtitle and header badge if data not available for that year
        // otherwise set
        if (k === 'area') {
          tooltip.tooltipHeader.subtitle = ''
          codebookLink = '/codebook#score'
        } else if (k === 'indicator') {
          tooltip.tooltipHeader.subtitle = ''
          const code = plugins.indicator.split('-')[0].trim()
          codebookLink = '/codebook#' + code
          // set indicator question
          if (v !== null) {
            tooltip.indicator = state['indicator-def']
            tooltip.indName = plugins.indicator
          }
        } else {
          codebookLink = '/codebook#score'
        }
        if (v !== null) {
          tooltip.tooltipHeader.badge = (
            <div className={styles.badgeContainer}>
              <div
                style={{
                  backgroundColor: badgeInfo.color,
                  color: isLightColor(badgeInfo.color) ? '#333' : 'white',
                }}
                className={classNames(styles.badge)}
              >
                {badgeInfo.label}
              </div>
              <span
                style={{
                  marginLeft: '20px',
                  fontSize: '1.1rem',
                  width: '200px',
                }}
              >
                {badgeInfo.def}
              </span>
            </div>
          )
        }
      } else if (v === null) {
        tooltip.tooltipMainContent = []
        tooltip.continue
      }
      // define standard trend key, e.g., "metric_name-trend"
      const trendKey = k + '-trend'

      // if there are trend data, get them, and define the visual
      // representation of the trend for the tooltip
      if (state[trendKey] !== undefined && state[trendKey] !== null) {
        // get pct 0..100 of the trend value
        const pct = state[trendKey]

        // get appropriate noun for trend direction
        let noun
        if (pct < 0) noun = 'decrease'
        else if (pct > 0) noun = 'increase'
        else noun = 'no change'

        // define the datum for visual representation of the trend
        item.trend = {
          pct,
          pct_fmt: (
            <span>
              <i className={classNames('material-icons', 'notranslate')}>
                play_arrow
              </i>
              {percentize(state[trendKey]).replace('-', '')}
            </span>
          ),
          noun,
          timeframe: thisMetricMeta.trendTimeframe,
          classes: [],
        }
      }
      // add item to tooltip content
      tooltip.tooltipMainContent.push(item)
    }
  }

  // add actions for bottom of tooltip
  tooltip.actions = [
    !noData && (
      <>
        <div>
          <a target="_blank" href={codebookLink} rel="noopener noreferrer">
            <span>go to codebook</span>
          </a>
          <a target="_blank" href="/sources" rel="noopener noreferrer">
            <span>go to sources</span>
          </a>
        </div>
        <a role="button" target="_blank" rel="noreferrer" href={`/${iso2}`}>
          <button key={'view'}>View country</button>
        </a>
      </>
    ),
  ]

  // if callback, trigger
  if (callback) callback()

  // return tooltip data to be used in component <MapTooltip />
  return tooltip
}

export const searchGetter = ({ q, data }) => {
  const qLower = q.toLowerCase()
  const matches = data.filter(
    d => d[1].toLowerCase().includes(qLower) && d[2] !== null
  )
  const newResults = matches.map(d => {
    return {
      name: d[1],
      type: d[3] || '',
      lngLat: [d[5], d[4]],
      id: d[0],
      iso2: d[2],
    }
  })
  return newResults.slice(0, 5)
}

export const placeGetter = async () => {
  return await PlaceQuery({ by_region: false, place_type: ['country'] })
}

const BarChart = ({ label, value, total }) => {
  const fracOfBar = 90 * (value / total) || 0
  return (
    <div className={styles.barChart}>
      <div className={styles.label}>
        {label !== 'Null' ? (
          label
        ) : (
          <span>
            Data not yet
            <br />
            available
          </span>
        )}
      </div>
      <div className={styles.bars}>
        <div className={styles.barBackground}></div>
        <div className={styles.barData}>
          <div
            style={{ width: `${fracOfBar}%` }}
            className={classNames(
              styles.fill,
              styles[label.toLowerCase().replace(' ', '')]
            )}
          ></div>
          <div
            style={{ left: `calc(${fracOfBar}% + 5px)` }}
            className={styles.value}
          >
            {value}
          </div>
        </div>
      </div>
    </div>
  )
}
