import axios from 'axios'
import Util, { isEmpty } from './Util.js'

// constants
const API_URL = process.env.GATSBY_METRICS_API_URL

/**
 * Get metric metadata from API.
 */
let allMetrics = null
const MetricQuery = async function ({ ...props }) {
  const params = {}
  for (const [k, v] of Object.entries(props)) {
    params[k] = v
  }

  // if no params are passed, and all metrics are cached, then return
  // all metrics
  const noParams = isEmpty(params)
  const metricsCached = allMetrics !== null
  if (noParams && metricsCached) {
    return allMetrics
  } else {
    const res = await axios(`${API_URL}/metric`, {
      params,
    })

    return res.data.data
    if (noParams && !metricsCached) {
      allMetrics = res.data.data
    }
  }
}

export default MetricQuery
