// standard packages
import React from 'react'
import ReactTooltip from 'react-tooltip'

// assets and styles
import styles from './timeframepicker.module.scss'
import imgSrc from '../../../assets/images/info.svg'

// local components

/**
 * @method TimeframePicker
 */
export const TimeframePicker = ({ year, setYear, allowAll = false }) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
      className={styles.select}
    >
      <div className={styles.selectLabel}>
        Show data as of{' '}
        <img
          className={styles.infoIcon}
          src={imgSrc}
          data-for="time-picker"
          data-tip=""
        />
      </div>
      <select
        value={year}
        onChange={v => {
          const newYear = v.target.value
          if (!['recent', 'all'].includes(newYear)) setYear(parseInt(newYear))
          else setYear(newYear)
        }}
        className={styles.selectList}
      >
        <option key={'recent'} value={'recent'}>
          Most recent data
        </option>
        {allowAll && (
          <option key={'all'} value={'all'}>
            All years
          </option>
        )}
        {[2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017].map(y => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
      <ReactTooltip
        id={'time-picker'}
        type="light"
        effect="float"
        place="left"
        delayHide={250}
      >
        <div className={styles.tooltip}>
          'Most recent data' shows most recent policy adoption levels and other
          data. Choosing a year shows data available for that year only.
        </div>
      </ReactTooltip>
    </div>
  )
}

export default TimeframePicker
