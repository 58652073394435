import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import styles from './nav.module.scss'
import ReactTooltip from 'react-tooltip'
import MapSearch from '../../common/MapboxMap/MapSearch/MapSearch'
import { InfoTooltip } from '../../common'
import twitter from '../../../assets/images/twitter.png'

import DropDown from './DropDown/DropDown'

// assets
import logo from '../../../assets/images/logo.svg'

// import codebook
import codebook from '../../../assets/codebook.json'
// get unique indicators (excluding subindicators)
const policies = {}
// prettier-ignore
codebook.data.forEach(
  policy => {
    const abbr = policy['Data element(s)'].split(':')[0].replace(/i/g, '')
    const name = policy['Data element(s)'].split(':')[1].trim()
    policies[abbr] = policies[abbr] || name
    }
)

const Nav = ({ page, ...props }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const linksRef = useRef(null)
  const hamburgerRef = useRef(null)
  const pickerRef = useRef(null)

  const [openDropDown, setOpenDropDown] = useState('')

  // EFFECT HOOKS // --------------------------------------------------------//
  // on click anywhere but in menu, and menu is shown, close menu; otherwise
  // do nothing
  useEffect(() => {
    document.getElementById('___gatsby').onclick = e => {
      if (linksRef === null || linksRef.current === null) return
      const links = linksRef.current
      if (links.contains(e.target) || hamburgerRef.current.contains(e.target))
        return
      else {
        setShowMobileMenu(false)
      }
    }
  }, [showMobileMenu])

  return (
    <div
      className={classNames(styles.navWrapper, {
        [styles.static]:
          page === 'country' || page === 'compare' || page === 'about',
        [styles.wide]: page === 'map',
        [styles.showMobileMenu]: showMobileMenu,
      })}
    >
      <div className={styles.nav}>
        <Link to={'/'}>
          <img src={logo} />
        </Link>
        <div className={styles.menu}>
          <button ref={hamburgerRef} className={styles.hamburger}>
            <i
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setShowMobileMenu(!showMobileMenu)
              }}
              className={classNames('material-icons', 'notranslate')}
            >
              {showMobileMenu ? 'close' : 'menu'}
            </i>
          </button>
          <div ref={linksRef} className={styles.links}>
            <Link
              onClick={() => {
                setShowMobileMenu(false)
              }}
              className={page === 'map' ? styles.active : ''}
              to={'/map'}
            >
              Map
            </Link>
            <DropDown
              {...{ openDropDown, setOpenDropDown }}
              page={page}
              pageName="country"
              title="By country"
              overflowY="visible"
            >
              <div className={styles.title}>By country</div>
              <MapSearch
                name="navSearch"
                noToggle={true}
                className={'nav'}
                links={true}
              />
            </DropDown>
            <DropDown
              {...{ openDropDown, setOpenDropDown }}
              page={page}
              pageName={'policy'}
              title="By policy"
            >
              {Object.entries(policies).map(([abbr, name]) => (
                <Link key={abbr} to={`/policy/${abbr}`}>
                  {abbr} - {name}
                </Link>
              ))}
            </DropDown>
            <DropDown
              {...{ openDropDown, setOpenDropDown }}
              page={page}
              pageName={'compare'}
              title="Analyze"
            >
              <Link to="/compare">Global progress</Link>
              <Link to="/globalcompare">Comparison tools</Link>
              <Link to="/data">Data access</Link>
            </DropDown>
            <DropDown
              {...{ openDropDown, setOpenDropDown }}
              page={page}
              pageName={'about'}
              title="About"
            >
              <Link to="/about">About</Link>
              <Link to="/howto">Site how-to</Link>
              <Link to="/methods">Methods</Link>
              <Link to="/codebook">Codebook</Link>
              <Link to="/sources">Sources</Link>
            </DropDown>
            <Link
              onClick={() => {
                setShowMobileMenu(false)
              }}
              className={page === 'Activities' ? styles.active : ''}
              to={'/activities'}
            >
              Activities
            </Link>
            <Link
              onClick={() => {
                setShowMobileMenu(false)
              }}
              className={classNames(
                page === 'contact' ? styles.active : '',
                styles.contactDrape
              )}
              to={'/contact'}
            >
              {showMobileMenu ? (
                'Contact Us'
              ) : (
                <div>
                  <i className="fas fa-envelope"></i>
                  Contact Us
                </div>
              )}
            </Link>
            <Link
              onClick={() => {
                setShowMobileMenu(false)
              }}
              className={classNames(styles.mailingDrape, styles.contactDrape)}
              to={'/contact#mailing-list'}
            >
              {showMobileMenu ? (
                'Join our Mailing List'
              ) : (
                <div>Join our Mailing List</div>
              )}
            </Link>
            {!showMobileMenu && (
              <a
                onClick={() => {
                  setShowMobileMenu(false)
                }}
                className={classNames(styles.twitterLogo)}
                href={'https://twitter.com/hivpolicylab'}
                target="_blank"
              >
                <img
                  src={twitter}
                  alt={'Twitter logo, links to HIV Policy Lab twitter page'}
                ></img>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav
