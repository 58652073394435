/**
 * Header with title and if applicable, flag for the details pages.
 */

// standard packages
import React from 'react'

// styles and assets
import styles from './countryheader.module.scss'
import { TimeframePicker } from '../../common'
import globe from '../../../assets/images/globe.png'

// import 3rd party packages
import classNames from 'classnames'

// FUNCTION COMPONENT // ----------------------------------------------------//
/**
 * Overview for specific country
 * @method CountryHeader
 */
const CountryHeader = ({
  iso2,
  name,
  noDataYet = false,
  noData,
  year,
  setYear,
  comparePage,
  ...props
}) => {
  // CONSTANTS // -----------------------------------------------------------//
  let flag =
    iso2 !== undefined || comparePage ? (
      <img
        className={classNames(styles.flag, { [styles.globe]: comparePage })}
        src={
          comparePage
            ? globe
            : `https://flags.talusanalytics.com/shiny_100px/${iso2.toLowerCase()}.png`
        }
      ></img>
    ) : null

  // UTILITY FUNCTIONS // ---------------------------------------------------//

  // EFFECT HOOKS // --------------------------------------------------------//

  // JSX // -----------------------------------------------------------------//
  if (!noData)
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.nameAndFlag}>
            {flag}
            <h1 className={styles.countryName}>{name}</h1>
          </div>
          {!noDataYet && (
            <TimeframePicker {...{ year, setYear, className: styles.select }} />
          )}
        </div>
      </div>
    )
  else return null
}

export default CountryHeader
