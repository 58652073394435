import axios from 'axios'
import Util from './Util.js'

// var API_BASE = process.env.REACT_APP_API_BASE_URL
// if (typeof API_BASE === 'undefined') {
//   API_BASE = 'http://localhost:5002'
// }

const API_URL = process.env.GATSBY_METRICS_API_URL

/**
 * Get observation data from API.
 * @method getObservations
 */

const ObservationQuery = async function ({
  metric_id,
  temporal_resolution,
  start_date,
  end_date,
  country = [],
  spatial_resolution = 'country',
  lag_allowed = 0,
  ...props
}) {
  end_date = typeof end_date !== 'undefined' ? end_date : start_date

  country = typeof country !== 'undefined' ? country : 'all'
  const params = new URLSearchParams({
    metric_id: metric_id,
    temporal_resolution: temporal_resolution,
    spatial_resolution: spatial_resolution,
    lag_allowed,
  })

  if (props.by_field) params.append('by_field', props.by_field)

  // Send start and end dates if they are provided, otherwise do not send.
  if (end_date !== undefined) params.append('start', end_date)
  if (start_date !== undefined) params.append('end', start_date)
  country.forEach(d => {
    params.append('place_id', d)
  })

  const res = await axios(`${API_URL}/observations`, {
    params,
  })

  return res.data.data
}

export default ObservationQuery
