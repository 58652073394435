// standard packages
import React, { useState } from 'react'
import classNames from 'classnames'

// third party packages
import { Link } from 'gatsby'
import styled from 'styled-components'

// assets and styles
import styles from './primarybutton.module.scss'

const PrimaryStyle = styled.button`
  color: white;
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: normal;

  i {
    position: relative;
    margin-right: 5px;
    left: -3px;
  }

  border: 2px solid transparent;
  border-radius: 4px;
  background: -moz-linear-gradient(top, #425379 0%, #213561 100%);
  background: -ms-linear-gradient(top, #425379 0%, #213561 100%);
  background: -o-linear-gradient(top, #425379 0%, #213561 100%);
  background: -webkit-linear-gradient(top, #425379 0%, #213561 100%);

  ${props => {
    if (!props.disabled) {
      return `
        &:hover,
        &:active {
          background: white;
          color: #213561;
          border-color: #213561;
        }

        &:active {
          box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
        }
      `
    } else
      return `
      opacity: .5;
    `
  }}
`
const SecondaryStyle = styled(PrimaryStyle)`
  background: white;
  color: #213561;
  border-color: #213561;
  &:hover,
  &:active {
    background: #fafafa;
  }
`

/**
 * @method PrimaryButton
 * Standard primary button for frontends.
 */
export const PrimaryButton = ({
  // button text
  label = 'Click here',

  // if using icon: material icon name
  iconName = null,

  // if link: URL to go to
  url = null,

  // if link: whether target is in app or external
  urlIsExternal = false,

  // secondary styling
  isSecondary = false,

  // callback for click event
  onClick = undefined,

  disabled = false,
}) => {
  const icon =
    iconName !== null ? (
      <i className={classNames('material-icons', 'notranslate')}>{iconName}</i>
    ) : null

  const unwrappedButton = isSecondary ? (
    <SecondaryStyle {...{ disabled, onClick }}>
      {icon}
      {label}
    </SecondaryStyle>
  ) : (
    <PrimaryStyle {...{ disabled, onClick }}>
      {icon}
      {label}
    </PrimaryStyle>
  )

  // put button in link if needed
  let button
  if (url !== null) {
    if (urlIsExternal) {
      button = (
        <a target={'_blank'} href={url} rel={'noreferrer'}>
          {unwrappedButton}
        </a>
      )
    } else {
      button = <Link to={url}>{unwrappedButton}</Link>
    }
  } else {
    button = unwrappedButton
  }

  // JSX // ---------------------------------------------------------------- //
  return <div className={styles.wrapper}>{button}</div>
}

export default PrimaryButton
