/**
 * Standard Mapbox map component for display metrics as fill and circle.
 * All data elements are defined in `plugins` directory on a per-project basis.
 * `plugins/data.js` defines metric metadata, metric data getter methods, etc.,
 *    , and default settings
 * `plugins/sources.js` defines the map sources and styles.
 * `plugins/layers.js` defines the layers and styles
 * TODO add support for other types of map layers
 */

// standard packages
import React, { useEffect, useState, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { renderToString } from 'react-dom/server'
import styles from './mapboxmap.module.scss'

// 3rd party packages
import ReactMapGL, { NavigationControl, Popup } from 'react-map-gl'
import classNames from 'classnames'

// local modules
import {
  mapMetrics,
  metricMeta,
  dataGetter,
  tooltipGetter,
} from './plugins/data'
import { mapSources } from './plugins/sources'
import { layerImages } from './plugins/layers'
import { initMap, bindFeatureStates } from './setup'
import { isEmpty, getAndListString } from '../../misc/Util'
import ResetZoom from './resetZoom/ResetZoom'
import MapTooltip from './mapTooltip/MapTooltip'

// common components
import { Legend, ShowMore, InfoTooltip } from '..'

// constants
const MAPBOX_ACCESS_TOKEN = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

// FUNCTION COMPONENT // ----------------------------------------------------//
/**
 * @method MapboxMap
 * @param  {[str]}  mapId        ID of map to show, e.g., 'us', 'global'
 * @param  {[type]}  mapStyle
 * @param  {[type]}  date         [description]
 * @param  {[type]}  circle       [description]
 * @param  {[type]}  fill         [description]
 * @param  {[type]}  filters      [description]
 * @param  {[type]}  props        [description]
 */
const MapboxMap = ({
  // id of map to show, e.g., `us`, `global`, ...
  mapId,

  // default viewport, style URL, etc. for map (see `plugins/sources.js`)
  mapStyle,

  // date of data to show on map, e.g., `2020-03-01` for 1 Mar 2020
  date,

  // ID of circle metric to show (see `plugins/data.js`)
  circle,

  // ID of fill metric to show (see `plugins/data.js`)
  fill,

  // currently enabled filters with allowed vals, e.g.,
  // `{"filter_name": [1, 2, 3]}`
  filters,

  // array of JSX components that should go on top of the map field, e.g.,
  // map options and legend components
  overlays,

  // the current value of the search bar for the map (string)
  searchValue,

  // the current search choice, e.g., { "lngLat": [..., ...], "id": 0 }
  // representing the geometry search result that was most recently chosen
  searchChoice,

  // sets the above
  setSearchChoice,

  // sets current app page, e.g., `mapbox`
  setPage,

  // sets loading spinner on/off
  setLoading,

  // other properties if any
  ...props
}) => {
  // CONSTANTS // -----------------------------------------------------------//
  // store default viewport lnglat and zoom so it can be initialized and reset
  const defaultViewport = mapStyle.defaultViewport

  // note explaining map provenance
  const mapSourceText = (
    <div className={styles.aboutThisMapTooltip} style={{ maxWidth: 300 }}>
      This map reflects borders shown in United Nations-issued reference maps as
      of August 31, 2020. More information is available{' '}
      <a
        target="_blank"
        href={
          'https://geoportal.dfs.un.org/arcgis/home/item.html?id=541557fd0d4d42efb24449be614e6887'
        }
      >
        here
      </a>
      .
    </div>
  )

  // STATE // ---------------------------------------------------------------//
  // store map reference which is frequently invoked to get the current
  // Mapbox map object in effect hooks
  let mapRef = useRef(null)

  // is map initially loaded?
  const [initializing, setInitializing] = useState(true)
  const [mapLoaded, setMapLoaded] = useState(false)

  // data to display in map -- reloaded whenever date or filter is changed
  const [data, setData] = useState(null)

  // current viewport of map
  const [viewport, setViewport] = useState(defaultViewport)

  // show or hide the legend
  const [showLegend, setShowLegend] = useState(true)

  // state management for tooltips
  const [cursorLngLat, setCursorLngLat] = useState([0, 0])
  const [showTooltip, setShowTooltip] = useState(false)
  const [mapTooltip, setMapTooltip] = useState(null)

  // state management for selected/hovered status of geometries
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [hoveredFeature, setHoveredFeature] = useState(null)

  // Whether the reset button is shown or not. True if viewport is other than
  // default, false otherwise
  const [showReset, setShowReset] = useState(false)

  // UTILITY FUNCTIONS // ---------------------------------------------------//
  /**
   * Revert to default viewport
   * @method resetViewport
   * @return {[type]}       [description]
   */
  const resetViewport = () => {
    // get map element
    const map = mapRef.getMap()

    // hide tooltip and reset button and fly to original position
    setShowTooltip(false)
    flyToLongLat(
      // lnglat
      [defaultViewport.longitude, defaultViewport.latitude],

      // zoom
      defaultViewport.zoom,

      // map object
      map,

      // post-fly callback
      () => {
        setShowReset(false)
      }
    )
  }

  /**
   * Fly user to specified longlat map location, and (if provided) to the
   * final zoom value -- otherwise the zoom value is 150% of the current
   * zoom value or 8, whichever is smaller.
   * @method flyToLongLat
   * @param  {array}     longlat   Longlat coord in decimal deg
   * @param  {float}     finalZoom Zoom value to end on, or null
   * @param  {object}     viewport  Viewport state variable
   * @param  {object}     mapRef    MapBox map reference object
   * @param  {function}     callback    Optional callback function when done
   */
  const flyToLongLat = (longlat, finalZoom, map, callback = () => { }) => {
    // hide tooltip
    setShowTooltip(false)

    // Get current zoom level.
    const curZoom = viewport.zoom

    // Set zoom level to fly to (0 to 24 inclusive). Either zoom in by 20% or
    // the minimum zoom level required to see facilities, whichever is
    // smaller. Use final zoom if it specified.
    const flyZoom = finalZoom !== null ? finalZoom : Math.min(4, curZoom * 1.5)

    // Start off flying
    let flying = true

    /**
     * When flying stops, update the viewport position to match the place
     * that was flown to.
     * @method onFlyEnd
     */
    function onFlyEnd() {
      // Delete the event listener for the end of movement (we only want it to
      // be called when the current flight is over).
      map.off('moveend', onFlyEnd)

      // If flying,
      if (flying) {
        // Stop flying,
        flying = false

        // Set viewport state to the flight destination and zoom level
        const newViewport = {
          width: '100%',
          height: '100%',
          longitude: longlat[0],
          latitude: longlat[1],
          zoom: flyZoom,
        }

        setViewport(newViewport)
        if (callback) callback()
      }
    }

    // Assign event listener so viewport is updated when flight is over.
    map.on('moveend', onFlyEnd)

    // Fly to the position occupied by the clicked cluster on the map.
    map.flyTo({
      center: longlat,
      zoom: flyZoom,
      bearing: 0,
      speed: 2,
      curve: 1,
      easing: function (t) {
        return t
      },
    })

    // show reset (assuming viewport is not the default one)
    setShowReset(true)
  }

  // prep map data: when data arguments or the mapstyle change, reload data
  // map data updater function
  const getMapData = async dataArgs => {
    setLoading(true)
    const newMapData = await dataGetter(dataArgs)
    if (!initializing) setLoading(false)
    newMapData.placesCollected = newMapData.places.map(d => d[2])
    setData(newMapData)
  }

  /**
   * Update the current map tooltip
   * @method updateMapTooltip
   * @param  {[type]}         map [description]
   * @return {[type]}             [description]
   */
  const updateMapTooltip = async ({ map }) => {
    if (selectedFeature !== null) {
      setShowTooltip(false)
      const newMapTooltip = (
        <MapTooltip
          key={selectedFeature.ISO_A3}
          {...{
            setSelectedFeature,
            selectedFeature,
            map,
            ...(await tooltipGetter({
              mapId: mapId,
              d: selectedFeature,
              include: [circle, fill],
              date,
              map,
              filters,
              plugins: { code: props.code, indicator: props.indicator },
              callback: () => {
                setShowTooltip(true)
              },
            })),
          }}
        />
      )
      setMapTooltip(newMapTooltip)
    }
  }

  // EFFECT HOOKS // --------------------------------------------------------//
  // trigger when search choice changes: select and fly to feature that matched,
  // setting it as the selected feature upon arrival
  useEffect(() => {
    if (searchChoice) {
      const map = mapRef.getMap()

      // deselect the currently selected feature
      if (selectedFeature !== null) {
        map.setFeatureState(selectedFeature, { clicked: false })
      }

      flyToLongLat(
        // lnglat
        searchChoice.lngLat,

        // zoom
        defaultViewport.zoom,

        // map object
        map,

        // callback
        () => {
          const source = mapSources[mapId].fill
          const features = map.queryRenderedFeatures({
            name: source.name,
            sourceLayer: source.sourceLayer,
            filter: ['==', ['get', 'ISO_A2'], searchChoice.iso2],
          })
          const newSelectedFeature = features[0]
          setCursorLngLat(searchChoice.lngLat)
          setSelectedFeature(newSelectedFeature)
          map.setFeatureState(newSelectedFeature, { clicked: true })
        }
      )
    }
  }, [searchChoice])

  // get latest map data if date, filters, plugins, or map ID are updated
  useEffect(() => {
    // called twice on page init
    getMapData({ date, filters, mapId, fill, ...props })
  }, [date, props.metric_id])

  // update map tooltip if the selected feature or metric are updated
  useEffect(() => {
    if (mapRef.getMap !== undefined) {
      const map = mapRef.getMap()
      updateMapTooltip({ map })
    }
  }, [selectedFeature, circle, fill])

  // hide map tooltip if selected feature changes to null
  useEffect(() => {
    if (selectedFeature === null) {
      setShowTooltip(false)
      setSearchChoice(undefined)
    }
  }, [selectedFeature])

  // toggle visibility of map layers if selected metrics or map ID are updated
  useEffect(() => {
    if (mapRef.getMap !== undefined) {
      // toggle visible layers based on selections
      const map = mapRef.getMap()
      if (map !== null && map.loaded()) {
        // define types of layers that should be checked
        const layerTypeInfo = [
          {
            sourceTypeKey: 'circle',
            layerListKey: 'circleLayers',
            curOption: circle,
          },
          {
            sourceTypeKey: 'fill',
            layerListKey: 'fillLayers',
            curOption: fill,
          },
        ]

        // for each type of layer to check, hide the layer and its auxiliary
        // layers if it's not the selected option for that layer type, or
        // show them otherwise
        layerTypeInfo.forEach(({ sourceTypeKey, layerListKey, curOption }) => {
          // are there layers of this type defined in the map sources?
          const hasLayersOfType =
            mapSources[mapId][sourceTypeKey] !== undefined &&
            mapSources[mapId][sourceTypeKey][layerListKey] !== undefined
          if (hasLayersOfType) {
            // get layers of this type (circle, fill, ...)
            const layersOfType = mapSources[mapId][sourceTypeKey][layerListKey]

            // for each layer determine whether it is visible
            layersOfType.forEach(layer => {
              // if layer is current option, it's visible
              const visible = layer.id === curOption
              const visibility = visible ? 'visible' : 'none'
              map.setLayoutProperty(
                layer.id + '-' + sourceTypeKey,
                'visibility',
                visibility
              )
              // same for any associated pattern layers this layer has
              if (layer.styleOptions.pattern === true) {
                map.setLayoutProperty(
                  layer.id + '-' + sourceTypeKey + '-pattern',
                  'visibility',
                  visibility
                )
              }

              // same for circle shadow layers
              if (sourceTypeKey === 'circle') {
                map.setLayoutProperty(
                  layer.id + '-' + sourceTypeKey + '-shadow',
                  'visibility',
                  visibility
                )
              }
            })
          } else return
        })
      }
    }
  }, [circle, fill, mapId])

  // initialize or update the map whenever the current map data changes
  useEffect(() => {
    // if no map data, do nothing
    if (data === null) return
    else {
      const map = mapRef.getMap()

      // if map has not yet loaded
      if (initializing) {
        // initialize the map
        initMap({
          map,
          mapId,
          data,
          callback: function afterMapLoaded() {
            // bind feature states to support data driven styling
            bindFeatureStates({ map, mapId, data })

            // load layer images, if any, for pattern layers
            layerImages.forEach(({ asset, name }) => {
              map.loadImage(asset, (error, image) => {
                if (error) throw error
                map.addImage(name, image)
              })
            })

            // Always hide certain layers, such as borders that aren't shown
            map.setLayoutProperty('border-admin-2-case', 'visibility', 'none')

            // set loading flag to false (this block won't run again for
            // this map)
            setInitializing(false)
            setLoading(false)
          },
        })
      } else {
        // if map had already loaded, then just bind feature states using the
        // latest map data
        bindFeatureStates({ map, mapId, data, selectedFeature })
        updateMapTooltip({ map })
      }
    }
  }, [data])

  // update attribution text to include an "about this map" link
  useEffect(() => {
    if (mapLoaded) ReactTooltip.rebuild()
  }, [mapLoaded])

  // MAP EVENT CALLBACKS // -------------------------------------------------//
  /**
   * Handle map clicks: Select or deselect fill and show / hide tooltips
   * @method handleClick
   * @param  {[type]}    e [description]
   * @return {[type]}      [description]
   */
  const handleClick = e => {
    // allow no interaction until map exists
    if (mapRef.current === null) return
    else {
      // was the cursor moving on the map?
      const cursorOnMap = e.target.classList.contains('overlays')
      if (!cursorOnMap) return

      // Get map reference object and sources for map
      const map = mapRef.getMap()

      // allow no interaction until map loaded
      if (map === null || !map.loaded()) return
      else {
        const sources = mapSources[mapId]

        // Get list of features under the mouse cursor.
        const features = map.queryRenderedFeatures(e.point)

        // Get fill and/or circle features that were under the cursor
        // TODO add other layer types as needed, currently only fill and circle
        const fillFeature = features.find(f => {
          const foundFeature =
            (f['layer']['source-layer'] === sources.fill.sourceLayer ||
              f['layer']['source-layer'] === sources.fill_static.sourceLayer) &&
            f.layer.type === 'fill'
          const skip = foundFeature && f.properties.ISO_A3 === 'ESH'
          return foundFeature && !skip
        })
        const circleFeature = features.find(f => {
          return (
            f['layer']['source-layer'] === sources.circle.sourceLayer &&
            f.layer.type === 'circle'
          )
        })
        let lineFeature
        if (sources.line !== undefined)
          lineFeature = features.find(f => {
            return (
              f['layer']['source-layer'] === sources.line.sourceLayer &&
              f.layer.type === 'line'
            )
          })

        // choose one feature from among the detected features to use as target.
        // circle takes precedence over fill feature since it is drawn on top
        const feature = lineFeature || circleFeature || fillFeature
        // console.log('feature')
        // console.log(feature)

        // deselect the currently selected feature
        if (selectedFeature !== null) {
          map.setFeatureState(selectedFeature, { clicked: false })
        }

        // if a feature was discovered, mark is as selected and show the tooltip
        if (feature) {
          setCursorLngLat(e.lngLat)
          setSelectedFeature(feature)
          map.setFeatureState(feature, { clicked: true })

          // if a touch event caused this, then fly to the touched point, to
          // facilitate navigation on mobile devices
          const wasTouch = e.pointerType === 'touch'
          if (wasTouch)
            flyToLongLat(
              // lnglat
              e.lngLat,

              // zoom
              viewport.zoom,

              // map object
              map

              // // post-fly callback
              // () => {
              //   setShowReset(false);
              // }
            )
        } else {
          // otherwise, mark no feature as selected and hide the tooltip
          setShowTooltip(false)
          setSelectedFeature(null)
        }
      }
    }
  }

  /**
   * Handle map mousemoves: highlight hovered fill
   * @method handleMouseMove
   * @param  {[type]}        e [description]
   * @return {[type]}          [description]
   */
  const handleMouseMove = e => {
    // allow no interaction until map exists
    if (mapRef.current === null) return
    else {
      const map = mapRef.getMap()
      // allow no interaction until map loaded
      if (map === null || !map.loaded()) return
      else {
        // if the cursor is not hovering on the map itself, unhover
        // all features
        const cursorOnMap = e.target.classList.contains('overlays')
        if (!cursorOnMap) {
          map.getContainer().parentElement.parentElement.style.cursor =
            'default'
          if (hoveredFeature !== null) {
            map.setFeatureState(hoveredFeature, { hovered: false })
            setHoveredFeature(null)
          }
          // otherwise, highlight any hovered feature that is in the list of
          // permitted `layers`
        } else {
          map.getContainer().parentElement.parentElement.style.cursor = 'grab'
          // if there was a lnglat point returned to check, proceed
          if (e.point !== null) {
            // get all features in the list of layers to check, including the
            // `disputed_areas` layer of gray polygons
            const layers = ['disputed_areas']
            if (circle) layers.push(circle + '-circle')
            if (fill) layers.push(fill + '-fill')
            const features = map.queryRenderedFeatures(e.point, {
              layers: layers,
            })

            // unhover the currently hovered feature if any
            if (hoveredFeature !== null) {
              map.setFeatureState(hoveredFeature, { hovered: false })
            }

            // if there is a feature hovered, get the first oen
            if (features.length > 0) {
              // set hovered feature to the new one
              const newHoveredFeature = features[0]
              const skip = newHoveredFeature.properties.ISO_A3 === 'ESH'
              if (!skip) {
                setHoveredFeature(newHoveredFeature)
                map.setFeatureState(newHoveredFeature, { hovered: true })

                // use pointer cursor when hovering on feature
                const isNotDisputedAreaFeature =
                  newHoveredFeature.sourceLayer !== 'disputed_areas'
                map.getContainer().parentElement.parentElement.style.cursor = isNotDisputedAreaFeature
                  ? 'pointer'
                  : 'grab'
              }

              // if no hovered feature, set it to null and use grab cursor
            } else {
              // use pointer when hovering on feature
              setHoveredFeature(null)
              map.getContainer().parentElement.parentElement.style.cursor =
                'grab'
            }
          }
        }
      }
    }
  }
  const attributionLinks = [
    {
      name: 'About this map',
      jsx: (
        <div
          data-for={'aboutThisMapTooltip'}
          data-tip={renderToString(mapSourceText)}
          data-html={true}
        >
          About this map
        </div>
      ),
    },
    {
      name: '© Mapbox',
      url: 'https://www.mapbox.com/about/maps/',
    },
    {
      name: '© OpenStreetMap',
      url: 'http://www.openstreetmap.org/about/',
    },
    {
      name: 'Improve this map',
      style: { fontWeight: 'bold' },
      url:
        'https://apps.mapbox.com/feedback/?owner=nicoletalus&id=ck36gyk4d03vq1coaj7rzzoc2&access_token=pk.eyJ1Ijoibmljb2xldGFsdXMiLCJhIjoiY2p4ZXcwYTBvMDJzZTQwbzdlbjdibGk5OSJ9.bS2fkZxdnvICHIP9LXcx-A',
    },
  ]

  // JSX // -----------------------------------------------------------------//
  // render map only after data initially load
  if (data === null) return <div />
  return (
    <>
      <ReactMapGL
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={map => {
          mapRef = map
        }}
        captureClick={true}
        mapStyle={mapStyle.url}
        {...viewport}
        maxZoom={mapStyle.maxZoom}
        minZoom={mapStyle.minZoom}
        onViewportChange={newViewport => {
          // set current viewport state variable to the new viewport
          setViewport(newViewport)
          const lngLatNotDefault =
            newViewport.longitude !== defaultViewport.longitude ||
            newViewport.latitude !== defaultViewport.latitude
          const zoomNotDefault = newViewport.zoom !== defaultViewport.zoom

          // If viewport deviates from the default zoom or lnglat, show the
          // "Reset" button, otherwise, hide it
          if (zoomNotDefault || lngLatNotDefault) setShowReset(true)
          else setShowReset(false)
        }}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onLoad={() => {
          setMapLoaded(true)
          // // when map has loaded, add event listener to update the map data
          // // whenever the map style, i.e., the type of map, is changed
          // const map = mapRef.getMap()
          // map.on('styledataloading', function () {
          //   getMapData()
          // })
        }}
        doubleClickZoom={false} //remove 300ms delay on clicking
      >
        {
          // map tooltip component
          showTooltip && (
            <div className={styles.mapboxMap}>
              <Popup
                id="tooltip"
                longitude={cursorLngLat[0]}
                latitude={cursorLngLat[1]}
                closeButton={false}
                closeOnClick={false}
                captureScroll={true}
                interactive={true}
              >
                {mapTooltip}
              </Popup>
            </div>
          )
        }

        {
          // map legend
        }
        <div className={styles.customOverlays}>
          {overlays}
          <div className={styles.attributionLinks}>
            {attributionLinks.map(d => (
              <>
                {!d.jsx && (
                  <div key={d.name} style={d.style}>
                    {d.name}
                  </div>
                )}
                {d.jsx && (
                  <div key={d.name} style={d.style}>
                    {d.jsx}
                  </div>
                )}
              </>
            ))}
          </div>

          <div className={styles.legend}>
            {
              <div
                className={classNames(styles.entries, {
                  [styles.show]: showLegend,
                })}
              >
                <button
                  onClick={e => {
                    // toggle legend show / hide on button click
                    e.stopPropagation()
                    e.preventDefault()
                    setShowLegend(!showLegend)
                  }}
                >
                  <div>{showLegend ? 'hide legend' : 'show legend'}</div>
                  <i
                    className={classNames('material-icons', 'notranslate', {
                      [styles.flipped]: showLegend,
                    })}
                  >
                    play_arrow
                  </i>
                </button>
                {
                  // fill legend entry
                  // note: legend entries are listed in reverse order
                }
                {circle !== null && (
                  <Legend
                    {...{
                      setInfoTooltipContent: props.setInfoTooltipContent,
                      classNames: ['mapboxLegend'],
                      key: 'basemap - quantized',
                      metric_definition: metricMeta[circle].metric_definition,
                      metric_displayname: (
                        <span>
                          {metricMeta[circle].metric_displayname({
                            date,
                            metricName: plugins.metricName,
                          })}
                        </span>
                      ),
                      ...metricMeta[circle].legendInfo.circle,
                    }}
                  />
                )}
                {
                  // fill legend entry
                }
                {fill !== null && (
                  <>
                    <Legend
                      {...{
                        setInfoTooltipContent: props.setInfoTooltipContent,
                        classNames: ['mapboxLegend'],
                        key: 'bubble - linear - desktop',
                        metric_definition: metricMeta[fill].metric_definition,
                        metric_displayname: metricMeta[fill].metric_displayname(
                          {
                            date,
                            metricName: props.metricName,
                          }
                        ),
                        ...metricMeta[fill].legendInfo.fill,
                        labelsInside: true,
                        labelsRight: false,
                      }}
                    />
                    <Legend
                      {...{
                        mobile: true,
                        setInfoTooltipContent: props.setInfoTooltipContent,
                        key: 'bubble - linear',
                        metric_definition: metricMeta[fill].metric_definition,
                        metric_displayname: metricMeta[fill].metric_displayname(
                          {
                            date,
                            metricName: props.metricName,
                          }
                        ),
                        ...metricMeta[fill].legendInfo.fill,
                        labelsInside: false,
                        labelsRight: true,
                        classNames: metricMeta[
                          fill
                        ].legendInfo.fill.classNames.concat([
                          'mobile',
                          'notRounded',
                        ]),
                      }}
                    />
                  </>
                )}
              </div>
            }
          </div>
        </div>
        {showReset && <ResetZoom handleClick={resetViewport} />}
        {
          // map zoom plus and minus buttons
        }
        <div className={styles.zoomButtonContainer}>
          <NavigationControl />
        </div>
      </ReactMapGL>
      {
        <ReactTooltip
          id={'aboutThisMapTooltip'}
          key={'aboutThisMapTooltip'}
          type="light"
          effect="solid"
          place="bottom"
          delayHide={250}
          clickable={true}
          isCapture={true}
          getContent={content => content}
        />
      }
    </>
  )
}

export default MapboxMap
