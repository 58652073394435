// import { window, document } from 'browser-monads'
import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from './mapsearch.module.scss'
import { Link } from 'gatsby'
import SearchIcon from './SearchIcon'

// local components
import { placeGetter, searchGetter } from '../plugins/data'

/**
 * Search bar to locate countries and zoom to them
 * @method MapSearch
 */

const MapSearch = ({
  noToggle,
  className = '',
  onSelect = () => '',
  links = false,
}) => {
  // show/hide search results
  const [show, setShow] = useState(false)
  const [expanded, setExpanded] = useState(true)

  // key up function

  // initial place data
  const [data, setData] = useState(null)

  // results
  const [results, setResults] = useState([])

  // loading?
  const [, setLoading] = useState(true)

  // maintain ref for input to track focus
  let inputRef = useRef(null)
  let resultsRef = useRef(null)

  const getData = async () => {
    const newData = await placeGetter()
    setData(newData)
    setLoading(false)
  }

  // initialize data
  useEffect(() => {
    getData()
  }, [])

  // handle event listener for closing search results on click
  useEffect(() => {
    document.getElementById('___gatsby').onclick = e => {
      if (inputRef === null || inputRef.current === null) return
      const input = inputRef.current
      if (
        (input && input.contains(e.target)) ||
        (resultsRef.current && resultsRef.current.contains(e.target))
      )
        return
      else {
        setShow(false)
      }
    }
  }, [show])

  const updateSearchResults = q => {
    const newResults = searchGetter({ q, data })
    setResults(newResults)
  }

  const resultsJsx = links
    ? results.length > 0 &&
      results.map((d, i) => (
        <Link
          role="search-result"
          to={'/' + d.iso2.toLowerCase()}
          key={d.name + '-' + d.type + '-' + i}
        >
          <div
            tabIndex={0}
            onKeyUp={e => {
              if (e.keyCode === 13) {
                setShow(false)
                inputRef.current.value = ''
                onSelect(d)
              }
            }}
            id={'result-' + i.toString()}
            className={styles.result}
          >
            <div className={styles.name}>{d.name}</div>
            <div className={styles.type}>{d.type}</div>
          </div>
        </Link>
      ))
    : results.length > 0 &&
      results.map((d, i) => (
        <div
          tabIndex={0}
          onClick={() => {
            setShow(false)
            inputRef.current.value = ''
            onSelect(d)
          }}
          onKeyUp={e => {
            if (e.keyCode === 13) {
              setShow(false)
              inputRef.current.value = ''
              onSelect(d)
            }
          }}
          id={'result-' + i.toString()}
          key={d.name + '-' + d.type + '-' + i}
          className={styles.result}
        >
          <div className={styles.name}>{d.name}</div>
          <div className={styles.type}>{d.type}</div>
        </div>
      ))

  return (
    <div
      className={classNames(styles.style, styles[className], {
        [styles.expanded]: expanded,
        [styles.noToggle]: noToggle === true,
      })}
    >
      <div className={styles.bar}>
        <input
          autoComplete={'new-password'} //prevent autocomplete suggestions
          className={classNames({ [styles.expanded]: expanded })}
          ref={inputRef}
          onKeyUp={e => {
            if (e.keyCode === 27) {
              setExpanded(false)
              setShow(false)
              inputRef.current.value = ''
            } else if (e.keyCode === 13) {
              if (
                resultsRef.current !== null &&
                resultsRef.current.children.length > 0
              ) {
                resultsRef.current.children[0].click()
              }
            } else if (e.keyCode === 40) {
              if (
                resultsRef.current !== null &&
                resultsRef.current.children.length > 0
              ) {
                resultsRef.current.children[0].focus()
              }
            }
          }}
          onClick={e => {
            if (!show && inputRef.current.value !== '') {
              setShow(true)
            }
          }}
          onChange={v => {
            if (v.target.value.trim() !== '') {
              updateSearchResults(v.target.value.trim())
              setShow(true)
            } else {
              setResults([])
              setShow(false)
            }
          }}
          type={'text'}
          placeholder={'Enter country name'}
        />
        <SearchIcon
          setShow={setShow}
          expanded={expanded}
          setExpanded={setExpanded}
          focus={inputRef.current !== null ? inputRef.current.focus : undefined}
          noToggle={noToggle}
        />
      </div>
      {show && (
        <div ref={resultsRef} className={styles.results}>
          {resultsJsx}
          {results.length === 0 && (
            <div className={styles.result}>
              <div className={styles.noResults}>
                No results for "{inputRef.current.value}"
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default MapSearch
