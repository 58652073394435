/**
 * Define Mapbox sources for geometries, centroids, etc. and styles.
 * All data elements are defined in `plugins` directory on a per-project basis.
 * `plugins/data.js` defines metric metadata, metric data getter methods, etc.,
 *    , and default settings
 * `plugins/sources.js` defines the map sources and styles.
 * `plugins/layers.js` defines the layers and styles
 */

// define map sources to provide features for plotting on the map
export const mapSources = {
  // additional map types, layer types, and source defs...
  global: {
    fill: {
      name: 'geoms',
      sourceLayer: 'countries',
      def: {
        type: 'vector',
        url: 'mapbox://nicoletalus.c768qb2r',
        // filter to control what features are returned for layers that are
        // displaying this metric
        promoteId: 'ISO_A2',
        filter: [
          '==',
          ['in', ['get', 'ISO_A3'], ['literal', ['TWN', 'HKG']]],
          false,
        ],
      },
    },
    fill_static: {
      name: 'geoms_static',
      sourceLayer: 'areas_v6b',
      // sourceLayer: 'areas_v5',
      // sourceLayer: 'disputed_areas',
      pattern: false,
      def: {
        type: 'vector',
        url: 'mapbox://nicoletalus.bwcqpsan',
        promoteId: 'id',
        // filter: ['==', ['get', 'id'], 9999],
        // url: 'mapbox://nicoletalus.cj68t6rz',
        // url: 'mapbox://nicoletalus.0volyta6',
        // url: 'mapbox://nicoletalus.ckdelwcco28ja24oap93modmw-5lx9p',
      },
    },
    line: {
      name: 'lines',
      sourceLayer: 'lines_v6',
      // sourceLayer: 'disputed_borders',
      def: {
        type: 'vector',
        url: 'mapbox://nicoletalus.bwcqpsan',
        // url: 'mapbox://nicoletalus.ckdelvvvd6brr23n7ywzkk6t3-5z9hi',
      },
    },
    circle: {
      name: 'centroids',
      sourceLayer: 'mvmupdatescentroidsv2-62u4it',
      def: {
        type: 'vector',
        url: 'mapbox://nicoletalus.c4ujj0o1',
        promoteId: 'id',
      },
    },
  },
}

// define map styles to provide base layers for the maps
// each specified style represents a different map instance
export const mapStyles = {
  // additional maps...
  global: {
    url: 'mapbox://styles/nicoletalus/ck36gyk4d03vq1coaj7rzzoc2',
    value: 'global',
    name: 'Global',
    tooltip: 'View country-level data for the world',
    minZoom: 1.5,
    maxZoom: 6,
    defaultViewport: {
      height: '100%',
      width: '100%',
      latitude: 19.72983258604616,
      longitude: 9.514188701842533,
      zoom: 2,
    },
  },
}
