// standard packages
import React from 'react'

// 3rd party packages
import axios from 'axios'
import moment from 'moment'

// local components
import MetricQuery from './MetricQuery'
import { getInitCap } from './Util'

const API_URL = process.env.GATSBY_METRICS_API_URL

export const Export = async ({
  filters = {},
  ordering = {},
  tabs = undefined,
}) => {
  const req = axios({
    url: `${API_URL}/export`,
    method: 'POST',
    responseType: 'blob',
    config: {
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    },
    data: { filters, ordering, tabs },
    params: {},
  })

  // TODO comments below
  const res = await req
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  const dateString = moment().format('YYYY-MM-DD')
  const fn = `HIV Policy Lab - Data Export ${dateString}.xlsx`
  link.setAttribute('download', fn)
  document.body.appendChild(link)
  link.click()
  return
}

export const Reference = async ({
  filters = {},
  by = ['iso2', 'year', 'code'],
}) => {
  const req = axios({
    url: `${API_URL}/references`,
    method: 'POST',
    responseType: 'application/json',
    config: {
      headers: {
        Accept: 'application/json',
      },
    },
    data: { filters },
    params: { by },
  })

  // TODO comments below
  const res = await req
  return res.data
}

export const Indicator = async ({ filters = {}, page, pagesize, ordering }) => {
  // // fix filter keys if needed
  // const filtersFormatted = {}
  // for (const key in filters) {
  //   filtersFormatted[key.replace('.', '_')] = filters[key]
  // }
  const req = axios({
    url: `${API_URL}/indicators`,
    method: 'POST',
    responseType: 'application/json',
    config: {
      headers: {
        Accept: 'application/json',
      },
    },
    // data: { filters: filtersFormatted, ordering },
    data: { filters, ordering },
    params: { page, pagesize },
  })

  // TODO comments below
  const res = await req
  // debugger
  return res.data
}

export const IndicatorMap = async () => {
  const req = axios({
    url: `${API_URL}/indicator_map`,
    method: 'GET',
    responseType: 'application/json',
    config: {
      headers: {
        Accept: 'application/json',
      },
    },
  })

  // TODO comments below
  const res = await req
  return res.data
}

let parsedMetrics

/**
 * Get metric IDs, names, descriptions, etc. from database to use in API
 * calls and menus throughout the site.
 * @method getData
 * @return {Promise} [description]
 */
export const getParsedMetrics = async () => {
  // if local cache of result available, return it
  if (parsedMetrics !== undefined) return parsedMetrics
  else {
    const queries = {
      metrics: MetricQuery({}),
    }
    const results = await execute({ queries })

    // get indicator names and area names
    // TODO move into API server if too much processing for browser
    const newIndicators = []
    const newRecentIndicators = []
    const newSubindicators = []
    const newAreas = []
    const newRecentOverallAdoptions = []
    const newAreaAdoptions = []
    const newRecentAreaAdoptions = []
    const newAreaScores = []
    const newRecentAreaScores = []
    const newEpiMetrics = []
    const indicatorNameMap = {}
    let overallId,
      overallRecentAdoptionId,
      overallRecentScoreThresholdId,
      overallAdoptionId
    results.metrics.forEach(d => {
      if (d.metric_name.startsWith('indicator')) {
        const arr = d.metric_name.split('_')
        const id = arr[1]
        const name = id + ' - ' + arr[2].replace('w/', 'with ')
        indicatorNameMap[id] = name
        d.metric_name = d.metric_name.replace('w/', 'with ')
        newIndicators.push({ value: d.metric_id, name })
      } else if (d.metric_name.startsWith('recent_indicator')) {
        // recent indicator // --------------------------------------------- //
        // The most recent non-null observation of an indicator, if one exists,
        // otherwise null
        const arr = d.metric_name.split('_')
        const id = arr[2]
        const name = id + ' - ' + arr[3].replace('w/', 'with ')
        d.metric_name = d.metric_name.replace('w/', 'with ')
        newRecentIndicators.push({ value: d.metric_id, name })
      } else if (d.metric_name.startsWith('subindicator')) {
        const arr = d.metric_name.split('_')
        const id = arr[1]
        const name = id + ' - ' + arr[2].replace('w/', 'with ')
        if (indicatorNameMap[id] === undefined) indicatorNameMap[id] = name
        d.metric_name = d.metric_name.replace('w/', 'with ')
        newSubindicators.push({ value: d.metric_id, name })
      } else if (d.metric_name.startsWith('epi')) {
        const arr = d.metric_name.split('_')
        const name = arr[1]
        newEpiMetrics.push({ value: d.metric_id, name })
      } else if (d.view_name === 'policy_area_score_threshold') {
        const arr = d.metric_name.split('_')
        const name = getInitCap(arr[2]).replace('/', ' / ')
        newAreaScores.push({ value: d.metric_id, name })
        newAreas.push({ value: d.metric_id, name })
      } else if (d.view_name === 'policy_area_adoption_view') {
        const arr = d.metric_name.split('_')
        const name = getInitCap(arr[2]).replace('/', ' / ')
        newAreaAdoptions.push({ value: d.metric_id, name })
      } else if (d.view_name === 'policy_area_recent_adoption') {
        const arr = d.metric_name.split('_')
        const name = getInitCap(arr[3]).replace('/', ' / ')

        newRecentAreaAdoptions.push({ value: d.metric_id, name })
      } else if (d.view_name === 'policy_area_recent_score') {
        const arr = d.metric_name.split('_')
        const name = getInitCap(arr[3]).replace('/', ' / ')

        newRecentAreaScores.push({ value: d.metric_id, name })
      } else if (d.view_name === 'overall_recent_adoption') {
        overallRecentAdoptionId = d.metric_id
      } else if (d.view_name === 'overall_recent_score_threshold') {
        overallRecentScoreThresholdId = d.metric_id
      } else if (d.metric_name === 'overall') {
        overallId = d.metric_id
      } else if (d.metric_name === 'overall_adoption_view') {
        overallAdoptionId = d.metric_id
      } else return
    })
    // set local cache for result
    parsedMetrics = {
      newIndicators,
      newRecentIndicators,
      newSubindicators,
      newAreas,
      newAreaAdoptions,
      newRecentAreaAdoptions,
      newAreaScores,
      newRecentAreaScores,
      newEpiMetrics,
      overallRecentAdoptionId,
      overallRecentScoreThresholdId,
      overallAdoptionId,
      overallId,
      indicatorNameMap,
      allMetrics: results.metrics,
    }
    return parsedMetrics
  }
}

/**
 * Execute queries in parallel
 * @method
 * @param  {[type]} queries [description]
 * @return {[type]}         [description]
 */
export const execute = async function ({ queries }) {
  const results = {}
  for (const [k, v] of Object.entries(queries)) {
    const res = await v
    results[k] = res
  }
  return results
}
